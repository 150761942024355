<template>
  <div class="project-bank-loan-detail">
    <h2 class="px-2 py-4">Lead Details</h2>

    <div class="card p-2 mb-2">
      <div v-if="dataLoaded" class="row justify-content-between">
        <div class="col-12 sm-col-6">
          <div class="loan-amount mb-2">
            <div>
              <span class="d-block label">Loan Amount Requested</span>
              RM {{ numberWithCommas(parseInt(lead.requestLoanAmount)) }}
            </div>
            <div
              v-if="lead.finalLoanAmount && lead.status === LEADSTATUS.APPROVED"
              class="mt-2"
            >
              <span class="d-block label">Final Loan Amount</span>
              <span class="fg-success">
                RM {{ numberWithCommas(parseInt(lead.finalLoanAmount)) }}
              </span>
            </div>
          </div>
        </div>

        <div class="col-12 sm-col-6 mb-2">
          <lead-status
            class="d-flex sm-justify-content-end"
            :status="lead.status"
            statusClass="py-1 px-4 rounded"
          ></lead-status>
        </div>
      </div>

      <!-- --------------- Actions --------------- -->
      <div v-if="canManage">
        <div
          v-if="lead.status == LEADSTATUS.PENDING"
          class="d-flex justify-content-end"
        >
          <button class="btn danger bordered medium" @click="openRejectLA">
            Reject Loan
          </button>
          <button
            class="btn danger bordered medium ml-1"
            @click="openDeclineLead"
          >
            Decline
          </button>
          <button class="btn main medium ml-1" @click="verifyLead">
            <i class="fas fa-check mr-1"></i>Verify
          </button>
        </div>

        <div
          v-if="lead.status == LEADSTATUS.IN_PROGRESS"
          class="d-flex justify-content-end"
        >
          <button class="btn danger bordered medium" @click="openRejectLA">
            Reject Loan
          </button>
          <button class="btn main medium ml-1" @click="openApproveLA">
            <i class="fas fa-check mr-1"></i>Approve Loan
          </button>
        </div>

        <div
          v-if="lead.status == LEADSTATUS.APPROVED"
          class="row justify-content-end"
        >
          <p class="hint-text">Pending for buyer to confirm the banker</p>
        </div>

        <div
          v-if="lead.status == LEADSTATUS.LOAN_CONFIRMED"
          class="d-flex justify-content-end"
        >
          <button
            class="btn main medium ml-1"
            @click="confirmLOModal.isShown = true"
          >
            <i class="fas fa-check mr-1"></i>Confirm LO Signed
          </button>
        </div>
      </div>
    </div>
    <!-- ========================= Buyer Detail ========================== -->
    <div class="card p-2 mb-3">
      <h4 class="mx-2 my-2">Buyer Detail</h4>

      <div class="p-2">
        <!-- Buyer contact -->
        <display-data label="Buyer Email">
          {{ lead.projectPurchaseReservation.email }}
        </display-data>
        <display-data label="Buyer Contact No.">
          {{ lead.projectPurchaseReservation.phoneContact }}
        </display-data>
      </div>

      <div v-if="dataLoaded" class="bordered-card row p-2">
        <!-- Buyer info -->
        <div class="col-12 row">
          <display-data :class="displayDataCSS" label="Buyer Name">
            {{ lead.projectPurchaseReservation.purchaserName1 }}
          </display-data>
          <display-data :class="displayDataCSS" label="Gender">
            {{ lead.projectPurchaseReservation.gender1 }}
          </display-data>
          <display-data
            v-if="lead.projectPurchaseReservation.purchaserName2"
            :class="displayDataCSS"
            label="Buyer 2 Name"
          >
            {{ lead.projectPurchaseReservation.purchaserName2 }}
          </display-data>
          <display-data
            v-if="lead.projectPurchaseReservation.gender2"
            :class="displayDataCSS"
            label="Buyer 2 Gender"
          >
            {{ lead.projectPurchaseReservation.gender2 }}
          </display-data>
        </div>
      </div>
    </div>

    <!-- ========================= Agent Detail ========================== -->
    <agent-info
      v-if="lead.agent"
      class="card p-2 mb-3"
      :agencyUser="lead.agent"
    ></agent-info>

    <!-- ========================= Banker Detail ========================== -->
    <div v-if="lead.banker" class="card p-2 mb-3">
      <h4 class="mx-2 my-2">Banker</h4>

      <div class="row p-2">
        <display-data :class="displayDataCSS" label="Name">
          {{ lead.banker.name }}
        </display-data>
        <display-data :class="displayDataCSS" label="Email">
          {{ lead.banker.user.email }}
        </display-data>
        <display-data :class="displayDataCSS" label="Bank">
          {{ lead.banker.bank }}
        </display-data>
      </div>
    </div>

    <!-- ========================= Unit Detail ========================== -->
    <div class="card p-2 mb-3">
      <h4 class="mx-2 my-2">Unit Detail</h4>

      <div v-if="dataLoaded" class="bordered-card row p-2 mb-3">
        <display-data :class="displayDataCSS" label="Project">
          {{ lead.project.name }}
        </display-data>
        <display-data :class="displayDataCSS" label="Developer">
          {{ lead.project.developer.name }}
        </display-data>
        <display-data :class="displayDataCSS" label="Project Type">
          {{ lead.project.projectType }}
        </display-data>
        <display-data :class="displayDataCSS" label="Property Category">
          {{ lead.project.propertyCategory }}
        </display-data>
        <display-data :class="displayDataCSS" label="Unit Plot No.">
          {{ lead.projectUnit.plot }}
        </display-data>
      </div>

      <div v-if="dataLoaded" class="row">
        <download-button
          class="col-12 md-col-6"
          label="Title"
          :downloadItem="unit.titleDeed"
          :filenamePrefix="`unit_${unit.id}_title_deed`"
          @download="downloadFile"
        >
        </download-button>
        <download-button
          class="col-12 md-col-6 md-pl-1"
          label="Additional Document"
          :downloadItem="unit.additionalDocument"
          :filenamePrefix="`unit_${unit.id}_additional_doc`"
          @download="downloadFile"
        >
        </download-button>
      </div>
    </div>

    <!-- ========================== Documents ============================ -->
    <div class="card p-2 mb-3">
      <h4 class="mx-2 my-2">Documents</h4>

      <div v-if="dataLoaded" class="row p-2">
        <div class="col-12 row mb-3">
          <p class="col-12 font-bold document-category-name mb-2">
            {{ lead.loanApplicationDocumentCategory.name }}
          </p>
          <div
            v-for="docField in lead.loanApplicationDocuments"
            :key="docField.id"
            class="col-12 mb-1"
          >
            <download-button
              :label="docField.title"
              :downloadItem="docField.document"
              :filenamePrefix="docField.title.toLowerCase()"
              @download="downloadFile"
            >
            </download-button>
          </div>
        </div>

        <!-- Resubmit Documents -->
        <div v-if="lead.resubmitLoanApplicationDocuments.length < 0">
          <p class="col-12 font-bold document-category-name mb-2">
            Resubmitted Documents
          </p>
          <div
            v-for="docField in lead.resubmitLoanApplicationDocuments"
            :key="docField.id"
            class="col-12 mb-1"
          >
            <download-button
              :label="docField.title"
              :downloadItem="docField.document"
              :filenamePrefix="docField.title.toLowerCase()"
              @download="downloadFile"
            >
            </download-button>
          </div>
        </div>
      </div>
    </div>
    <!-- ======================= Modal: Decline Lead ======================== -->
    <modal v-model="declineLeadModal.isShown">
      <decline-lead
        @cancel="declineLeadModal.isShown = false"
        @submit="declineLead"
      ></decline-lead>
    </modal>
    <!-- ======================= Modal: Approve LA ========================= -->
    <modal v-model="approveLAModal.isShown">
      <ApproveLA
        @cancel="approveLAModal.isShown = false"
        @submit="approveLA"
      ></ApproveLA>
    </modal>
    <!-- ======================= Modal: Reject LA ========================= -->
    <modal v-model="rejectLAModal.isShown">
      <RejectLA
        @cancel="rejectLAModal.isShown = false"
        @submit="rejectLA"
      ></RejectLA>
    </modal>
    <!-- ======================= Modal: Reject LA ========================= -->
    <modal v-model="confirmLOModal.isShown">
      <ConfirmLO
        @cancel="confirmLOModal.isShown = false"
        @submit="confirmLOSigned"
      ></ConfirmLO>
    </modal>
  </div>
</template>

<script>
import { projectLeadStatus as LEADSTATUS } from "@/enums";
import { getFileExtension, numberWithCommas } from "@/utils/string";

export default {
  components: {
    DisplayData: () => import("@/components/GlobalComponents/DisplayData"),
    AgentInfo: () =>
      import(
        "@/components/GlobalComponents/Shared/ProjectSales/General/AgentInfo"
      ),
    DownloadButton: () =>
      import(
        "@/components/GlobalComponents/Shared/Banker/BankLoanDownloadButton"
      ),
    DeclineLead: () =>
      import("@/components/GlobalComponents/Shared/Banker/BankLoanDeclineLead"),
    ApproveLA: () =>
      import("@/components/GlobalComponents/Shared/Banker/BankLoanApproveLA"),
    RejectLA: () =>
      import("@/components/GlobalComponents/Shared/Banker/BankLoanRejectLA"),
    ConfirmLO: () =>
      import("@/components/GlobalComponents/Shared/Banker/BankLoanConfirmLO"),
    LeadStatus: () =>
      import("@/components/GlobalComponents/Shared/Banker/LeadStatus")
  },
  mixins: [],
  props: {
    canManage: {
      type: Boolean,
      default: true
    },
    downloadFileAPI: {
      type: Function,
      required: true
    },
    getBankLoanAPI: {
      type: Function,
      required: true
    },
    verifyLeadAPI: {
      type: Function,
      required: true
    },
    declineLeadAPI: {
      type: Function,
      required: true
    },
    approveLAAPI: {
      type: Function,
      required: true
    },
    rejectLAAPI: {
      type: Function,
      required: true
    },
    confirmLOSignedAPI: {
      type: Function,
      required: true
    },
    listingRouteName: {
      type: String,
      default: "ManageBankLoans"
    }
  },
  data: function () {
    return {
      lead: {},
      unit: {},
      dataLoaded: false,

      declineLeadModal: {
        isShown: false
      },
      approveLAModal: {
        isShown: false
      },
      rejectLAModal: {
        isShown: false
      },
      confirmLOModal: {
        isShown: false
      },

      displayDataCSS: "col-12 sm-col-6 md-col-4 lg-col-3",
      LEADSTATUS,
      numberWithCommas
    };
  },
  computed: {},
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {
    this.init();
  },
  methods: {
    async init() {
      try {
        this.$store.commit("setIsLoading", true);
        await this.getLeadData();
        this.dataLoaded = true;
        this.$store.commit("setIsLoading", false);
      } catch (error) {
        this.$store.commit("setIsLoading", false);
      }
    },
    openDeclineLead() {
      this.declineLeadModal.isShown = true;
    },
    openApproveLA() {
      this.approveLAModal.isShown = true;
    },
    openRejectLA() {
      this.rejectLAModal.isShown = true;
    },
    // =========================================================================
    async getLeadData() {
      try {
        let data = await this.getBankLoanAPI(this.$route.params.id);
        this.lead = this._.cloneDeep(data);
        this.unit = this._.cloneDeep(data.projectUnit);
      } catch (error) {
        this.$notify({
          group: "alert",
          type: "error",
          title: "Error",
          text: "Failed to get lead data. Please try again later."
        });
        throw error;
      }
    },
    async downloadFile(file, label, loading) {
      loading(true);
      let url = this.$getFileURL(file);
      let ext = getFileExtension(file.fileName);

      await this.downloadFileAPI(url, label, ext);
      loading(false);
    },
    // ------------- Verify & Decline Lead -------------
    async verifyLead() {
      let confirm = await this.$confirm({
        title: "Confirm Verify Lead",
        message: "Are you sure you want to verify this lead?",
        confirmText: "Verify"
      });
      if (confirm) {
        try {
          this.$store.commit("setIsLoading", true);
          await this.verifyLeadAPI(this.$route.params.id);
          this.$notify({
            group: "alert",
            type: "success",
            title: "Success",
            text: "Lead's bank loan has been verified successfully."
          });

          await this.getLeadData();

          this.$store.commit("setIsLoading", false);
        } catch (error) {
          this.$store.commit("setIsLoading", false);
          this.$notify({
            group: "alert",
            type: "error",
            title: "Error",
            text: "Failed to verify lead's bank loan. Please try again later."
          });
        }
      }
    },
    async declineLead(payload) {
      try {
        this.$store.commit("setIsLoading", true);
        await this.declineLeadAPI(this.$route.params.id, payload);
        this.declineLeadModal.isShown = false;

        this.$notify({
          group: "alert",
          type: "success",
          title: "Success",
          text: "Lead's bank loan has been declined."
        });

        await this.getLeadData();

        this.$store.commit("setIsLoading", false);
      } catch (error) {
        this.$store.commit("setIsLoading", false);
        this.$notify({
          group: "alert",
          type: "error",
          title: "Error",
          text: "Failed to decline lead's bank loan. Please try again later."
        });
      }
    },
    // ------------- Approve & Reject LA -------------
    async approveLA(payload) {
      try {
        this.$store.commit("setIsLoading", true);
        await this.approveLAAPI(this.$route.params.id, payload);

        this.approveLAModal.isShown = false;

        this.$notify({
          group: "alert",
          type: "success",
          title: "Success",
          text: "Lead's bank loan has been approved."
        });

        await this.getLeadData();

        this.$store.commit("setIsLoading", false);
      } catch (error) {
        this.$store.commit("setIsLoading", false);
        this.$notify({
          group: "alert",
          type: "error",
          title: "Error",
          text: "Failed to approve lead's bank loan. Please try again later."
        });
      }
    },
    async rejectLA(payload) {
      try {
        this.$store.commit("setIsLoading", true);
        await this.rejectLAAPI(this.$route.params.id, payload);

        this.rejectLAModal.isShown = false;

        this.$notify({
          group: "alert",
          type: "success",
          title: "Success",
          text: "Lead's bank loan has rejected."
        });

        await this.getLeadData();

        this.$store.commit("setIsLoading", false);
      } catch (error) {
        this.$store.commit("setIsLoading", false);
        this.$notify({
          group: "alert",
          type: "error",
          title: "Error",
          text: "Failed to reject lead's bank loan. Please try again later."
        });
      }
    },
    async confirmLOSigned(payload) {
      try {
        this.$store.commit("setIsLoading", true);
        await this.confirmLOSignedAPI(this.$route.params.id, payload);

        this.$notify({
          group: "alert",
          type: "success",
          title: "Confirmed LO Signed",
          text: "Letter of Offer has been confirm signed."
        });

        this.$router.push({ name: this.listingRouteName });

        this.$store.commit("setIsLoading", false);
      } catch (error) {
        this.$store.commit("setIsLoading", false);
        this.$notify({
          group: "alert",
          type: "error",
          title: "Error",
          text: "Failed to confirm Letter of Offer as signed. Please try again later."
        });
      }
    }
  }
};
</script>

<style lang="scss">
.project-bank-loan-detail {
  .hint-text {
    color: #808080;
  }
  .document-category-name {
    font-size: 18px;
  }
  .loan-amount {
    .label {
      color: #666;
      font-size: 12px;
      text-transform: uppercase;
    }
    font-size: 24px;
    font-weight: bold;
    color: #545454;
    border-radius: 4px;
    width: fit-content;
  }
}
</style>
