<template>
  <div class="manage-project-mortgage-bank-loan-detail">
    <div class="container fluid">
      <bank-loan-detail
        v-if="!_.isEmpty(API)"
        :canManage="false"
        :downloadFileAPI="downloadFile"
        :getBankLoanAPI="API.getBankLoan"
        :verifyLeadAPI="API.verifyLead"
        :declineLeadAPI="API.declineLead"
        :approveLAAPI="API.approveLA"
        :rejectLAAPI="API.rejectLA"
        :confirmLOSignedAPI="API.confirmLOSigned"
        :listingRouteName="getListingRouteName()"
      ></bank-loan-detail>
    </div>
  </div>
</template>

<script>
import { projectMortgage as mortgageAPI } from "@/api";
import BankLoanDetail from "@/components/GlobalComponents/Shared/Banker/BankLoanDetail";
import { downloadFile } from "@/utils/download";

export default {
  components: {
    BankLoanDetail
  },
  mixins: [],
  props: {
    isAssigned: {
      type: Boolean,
      default: false
    }
  },
  data: function () {
    return {
      mortgageAPI,
      downloadFile,
      API: {}
    };
  },
  computed: {},
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {
    this.initAPI();
  },
  methods: {
    initAPI() {
      this.API = mortgageAPI.getProjectMortgageAPI(this.isAssigned);
    },
    getListingRouteName() {
      return this.isAssigned
        ? "ManageAssignedProjectMortgageBankLoan"
        : "ManageProjectMortgageBankLoan";
    }
  }
};
</script>

<style lang="scss"></style>
